<!--
File: ImportForm.vue
Description: form for bulk import/upload of road data.
-->
<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-content>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <ImportTypesDropdown class='region-select-dropdown' :label="$t('label.select_import_type')"
              v-model="selected_import_type" @input='onImportTypeChange' />
          </div>
          <div class="md-layout-item md-small-size-10.0 md-size-50">
            <md-field>
              <label>{{ $t('traffic.select_file') }}</label>
              <md-file v-model="fileName.name" @md-change="onFileChange($event)" />
            </md-field>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success md-raised" @click="templateDownload" :disabled="!selected_import_type">
              {{ $t('buttons.download_template') }}
            </md-button>
            <md-button class="md-success md-raised" native-type="submit" required @click.native.prevent="validate"
              :disabled="upload_disabled">
              <md-icon>upload</md-icon>
              {{ $t('buttons.import') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onCancel">
              {{ $t('buttons.cancel') }}
            </md-button>
          </div>
        </md-card-actions>
      </md-card>

      <md-card v-if="show_preview == true">
        <md-card-content>
          <div>
            <label><strong>{{ $t('upload.status') }}: </strong> {{ status }} </label><br>
          </div>
          <md-table v-model="import_status" md-fixed-header class="paginated-table table-striped table-hover">
            <md-table-row slot="md-table-row" slot-scope="{item}">
              <md-table-cell :md-label="$t('upload.line_number')">
                {{ item.line_number }}
              </md-table-cell>
              <md-table-cell :md-label="$t('upload.type_description')">
                {{ item.type_description }}
              </md-table-cell>
              <md-table-cell :md-label="$t('upload.is_error')">
                {{ item.is_error == true ? $t('upload.yes') : $t('upload.no') }}
              </md-table-cell>
              <md-table-cell :md-label="$t('upload.message')" md-sort-by="message" width="10%">
                {{ item.message }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
  import Swal from 'sweetalert2'
  import ImportTypesDropdown from '../Dropdowns/ImportTypesDropdown.vue'

  export default {
    name: 'import-roads',
    components: {
      ImportTypesDropdown
    },

    data() {
      return {
        content: [],
        importFile: null,
        fileName: { name: '', },
        maxFileSize: null,
        selected_import_type: null,
        importTypeKey: null,

        show_preview: false,
        status: null,
        batch_id: null,

        upload_in_progress: false
      }
    },

    methods: {
      onFileChange(evt) {
        this.importFile = evt[0]
        this.show_preview = false
        this.status = null
      },
      onCancel() {
        this.$router.push('/inventory_data/roads')
      },
      templateDownload() {
        const templateType = `GET_${this.importTypeKey.toUpperCase()}_IMPORT_TEMPLATE`

        this.$store.dispatch(templateType).then((res) => {
          console.log(res.headers)
          const link = document.createElement('a')
          link.href = URL.createObjectURL(new Blob([res.data]))
          link.download = `${this.importTypeKey}_import_template.xlsx` //"road_import_template.xlsx"
          link.click()
          URL.revokeObjectURL(link.href)
        })
      },
      validate() {
        this.upload_in_progress = true
        const alert = {
          type: 'error',
          footer: this.$t('stdCols.name') + ': ' + this.importFile.name + "<br>" +
            this.$t(`label.filesize`, { size: this.importFile.size })
        }

        if (this.importFile.type.includes("image")) {
          alert.text = this.$t('The file type is wrong!')
        }
        else if (this.importFile.size > this.maxFileSize) {
          alert.text = this.$t('The file is too big!')
        }
        else {
          let formData = new FormData();
          formData.append('file', this.importFile)

          alert.type = 'success'
          alert.text = this.$t(`messages.import_file_queued`)
          this.$store.dispatch(`RUN_${this.importTypeKey.toUpperCase()}_IMPORT`, formData).then(
            (res) => {
              this.status = res.msg + ", " + res.result
              this.batch_id = res.batch_id
              this.get_log(this.batch_id)
              this.upload_in_progress = false
              this.show_preview = true
            },
            (err) => {
              alert.type = 'error'
              alert.text = this.$t(`messages.import_error`)
              alert.footer = err
            }
          )
          //Swal.fire(alert)
          //return
        }

        // file did not pass the check
        Swal.fire(alert)
        this.importFile = null
        this.fileName.name = ''
      },
      get_log(batch_id) {
        this.$store.dispatch('IMPORT_LOG_ALL', batch_id).then(() => {
          console.log(this.import_status)
        })
      },
      onImportTypeChange(import_type_id, key) {
        this.importTypeKey = key
        this.importFile = null
        this.fileName.name = ''
      }
    },

    mounted() {
      this.$store.dispatch('LOAD_SETTING_BY_NAME', 'MaxFileSize').then((res) => {
        this.maxFileSize = res * 1024   // in KB
      })
    },

    computed: {
      import_status() {
        return this.$store.state.ImportTools.import_status
      },
      upload_disabled() {
        return this.importFile === null || this.upload_in_progress === true
      }
    },
    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}
</style>