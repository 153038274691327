<!--
File: ImportTypesDropdown.vue
Description: show the dropdown combo with the Import Types list .
-->
<template>
  <md-field>
    <label for="import_type_id">{{ label }}</label>
    <md-select v-model='import_type_id' name="import_type_id" id="import_type_id" :disabled="disabled"
      :required="is_required" @md-selected='onChange'>
      <md-option v-for='(list, ind) in import_types_list' :key='ind' :value='list.import_type_id'>
        {{ list.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'import_types-dropdown',

    props: {
      label: { default: null, type: String },
      initial_value: { default: null, type: Number },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        import_type_id: null
      }
    },

    mounted() {
      this.$store.dispatch('GET_IMPORT_TYPES_DROPDOWN').then(() => {
        this.import_type_id = this.initial_value
      })
    },

    methods: {
      onChange() {
        const key = !this.import_type_id ? '' : this.import_types_list.find(
          (element) => element.import_type_id == this.import_type_id).key

        this.$emit('input', this.import_type_id, key)
      },
    },

    computed: {
      import_types_list() {
        return this.$store.state.Dropdowns.import_types;
      }
    }
  }
</script>